/*import React, { useState, useEffect } from 'react';
import { ReactComponent as Hex1 } from '../assets/images/svg/hero/1.svg';
import { ReactComponent as Hex2 } from '../assets/images/svg/hero/2.svg';
import { ReactComponent as Hex4 } from '../assets/images/svg/hero/4.svg';
import { motion, AnimatePresence } from 'framer-motion';

const Hero = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [currentHexIndex, setCurrentHexIndex] = useState(0);
  const hexComponents = [Hex1, Hex2, Hex4];
  const texts = [
    "Your pathway to the future grid",
    "Improve network operation control"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prev) => (prev + 1) % texts.length);
      setTimeout(() => {
        setCurrentHexIndex((prev) => (prev + 1) % hexComponents.length);
      }, Math.floor(Math.random() * 1500) + 500);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const CurrentHexComponent = hexComponents[currentHexIndex];

  return (
    <div className="bg-[#E0E7ED] relative overflow-hidden">
      <div className="container mx-auto px-4 min-h-[412px] md:h-[412px] flex flex-col md:flex-row items-center justify-between">
        <div className="relative z-10 w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0">
          <motion.h1 
            className="text-[#002A51] text-4xl sm:text-5xl lg:text-6xl font-bold mb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            wizzgrid
          </motion.h1>
          <AnimatePresence mode='wait'>
            <motion.p 
              key={currentTextIndex}
              className="text-[#002A51] text-xl sm:text-2xl max-w-md mx-auto md:mx-0"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              {texts[currentTextIndex]}
            </motion.p>
          </AnimatePresence>
        </div>
      </div>
      <div className="absolute right-0 bottom-0 w-full md:w-1/2 h-[250px] md:h-[388px] mt-6 pointer-events-none">
        <CurrentHexComponent className="absolute right-0 bottom-0 w-auto h-full object-contain object-right-bottom transition-opacity duration-500" />
      </div>
    </div>
  );
};

export default Hero;*/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as Hex1 } from '../assets/images/svg/hero/1.svg';
import { ReactComponent as Hex2 } from '../assets/images/svg/hero/2.svg';
import { ReactComponent as Hex3 } from '../assets/images/svg/hero/3.svg';
import { ReactComponent as Hex4 } from '../assets/images/svg/hero/4.svg';
import AnimatedSvgWrapper from './AnimatedSvgWrapper';
import AnimatedCursorArrow from './AnimatedCursor';

const texts = [
  "Your pathway to the future grid",
  "Improve network operation control"
];

const hexComponents = [Hex1, Hex2, Hex3, Hex4];

const Hero: React.FC = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [currentHexIndex, setCurrentHexIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const animationTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const textInterval = setInterval(() => {
      setCurrentTextIndex((prev) => (prev + 1) % texts.length);
    }, 4000);

    return () => clearInterval(textInterval);
  }, []);

  const handleHexHover = useCallback(() => {
    if (!isAnimating) {
      setIsAnimating(true);
      setCurrentHexIndex((prev) => (prev + 1) % hexComponents.length);
      
      animationTimeoutRef.current = setTimeout(() => {
        setIsAnimating(false);
      }, 3300);
    }
  }, [isAnimating]);

  useEffect(() => {
    return () => {
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
    };
  }, []);

  const CurrentHex = hexComponents[currentHexIndex];

  return (
    <AnimatedCursorArrow>
      <div className="hero-section bg-bg-secondary relative overflow-hidden">
        <div className="container mx-auto px-4 min-h-[412px] md:h-[412px] flex flex-col md:flex-row items-center justify-between">
          <div className="relative z-10 w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0">
            <motion.h1 
              className="text-primary text-4xl sm:text-5xl lg:text-6xl font-bold mb-4"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              wizzgrid
            </motion.h1>
            <AnimatePresence mode='wait'>
              <motion.p 
                key={currentTextIndex}
                className="text-primary text-xl sm:text-2xl max-w-md mx-auto md:mx-0"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
              >
                {texts[currentTextIndex]}
              </motion.p>
            </AnimatePresence>
          </div>
        </div>
        <div className="absolute right-0 bottom-0 w-full md:w-1/2 h-[250px] md:h-[388px] mt-6">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentHexIndex}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <AnimatedSvgWrapper>
                <CurrentHex 
                  onMouseEnter={handleHexHover} 
                  className="absolute right-0 bottom-0 w-auto h-full object-contain object-right-bottom transition-opacity duration-500" 
                />
              </AnimatedSvgWrapper>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </AnimatedCursorArrow>
  );
};

export default Hero;