import React, { useState, useRef, useEffect } from 'react';

export interface HexoidProps {
  title: string;
  text: string;
  color?: 'blue' | 'green' | 'gray' | 'white';
  fontColor?: string;
}

const Hexoid: React.FC<HexoidProps> = ({ title, text, color = 'white', fontColor = 'text-primary' }) => {
  const [tilt, setTilt] = useState({ x: 0, y: 0 });
  const hexoidRef = useRef<HTMLDivElement>(null);

  const colorClasses = {
    blue: 'bg-primary text-white',
    green: 'bg-secondary text-primary',
    gray: 'bg-bg-secondary text-primary',
    white: 'bg-white text-primary',
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!hexoidRef.current) return;
      const rect = hexoidRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const centerX = rect.width / 2;
      const centerY = rect.height / 2;
      const tiltX = (x - centerX) / 10;
      const tiltY = (centerY - y) / 10;
      setTilt({ x: tiltX, y: tiltY });
    };

    const handleMouseLeave = () => {
      setTilt({ x: 0, y: 0 });
    };

    const element = hexoidRef.current;
    if (element) {
      element.addEventListener('mousemove', handleMouseMove);
      element.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (element) {
        element.removeEventListener('mousemove', handleMouseMove);
        element.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);

  return (
    <div 
      ref={hexoidRef}
      className="hexoid-container relative w-72 h-80 transition-transform duration-200 ease-out"
      style={{
        transform: `perspective(1000px) rotateX(${tilt.y}deg) rotateY(${tilt.x}deg)`,
      }}
    >
      {color === 'white' && (
        <div className="hexoid absolute inset-0 bg-primary"></div>
      )}
      <div className={`hexoid absolute ${color === 'white' ? 'inset-[10px]' : 'inset-0'} ${colorClasses[color]} ${fontColor}`}>
        <div className="hexoid-inner absolute inset-0 flex flex-col justify-center items-center p-6 text-center">
          <h3 className="text-3xl font-bold mb-4">{title}</h3>
          <p className="text-lg">{text}</p>
        </div>
      </div>
    </div>
  );
};

export default Hexoid;