import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as WizzgridLogo } from '../assets/images/svg/wizzgrid.svg';
import { ReactComponent as ArrowIcon } from '../assets/images/svg/icons/arrow.svg';
import BookDemoButton from './BookDemoButton';

const Header: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const headerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (e: React.MouseEvent<HTMLAnchorElement>, id: string) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element && headerRef.current) {
      const headerHeight = headerRef.current.offsetHeight;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems = ['Introduction', 'Features', 'Benefits', 'Wizzgrid modules', 'References', 'About us', 'Contact'];

  return (
    <header
      ref={headerRef}
      className={`sticky top-0 z-50 bg-bg-primary font-chakra flex justify-between items-center px-4 md:px-8 py-2 transition-shadow duration-300 ${isScrolled ? 'shadow-md' : ''}`}
    >
      <WizzgridLogo className="h-[50px] md:h-[74px] w-auto" />
      <div className="flex items-center">
        <nav className="hidden lg:flex items-center space-x-6">
          {menuItems.map((item) => (
            <a
              key={item}
              href={`#${item.toLowerCase().replace(' ', '-')}`}
              className="text-primary font-medium hover:text-secondary transition-colors"
              onClick={(e) => scrollToSection(e, item.toLowerCase().replace(' ', '-'))}
            >
              {item}
            </a>
          ))}
        </nav>
        <div className="hidden lg:block ml-6">
          <BookDemoButton />
        </div>
        <button
          onClick={toggleMenu}
          className="lg:hidden text-primary hover:text-secondary ml-4"
          aria-label="Toggle menu"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
      </div>
      {isMenuOpen && (
        <div className="absolute top-full left-0 right-0 bg-bg-primary shadow-md lg:hidden">
          <nav className="flex flex-col items-center py-4">
            {menuItems.map((item) => (
              <a
                key={item}
                href={`#${item.toLowerCase().replace(' ', '-')}`}
                className="text-primary font-medium hover:text-secondary transition-colors py-2"
                onClick={(e) => scrollToSection(e, item.toLowerCase().replace(' ', '-'))}
              >
                {item}
              </a>
            ))}
            <div className="mt-4">
              <BookDemoButton />
            </div>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;