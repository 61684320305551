import React from 'react';
import screensImage from '../assets/images/png/screens.png';
import BookDemoButton from './BookDemoButton';

const Introduction = () => {
  return (
    <div id="introduction" className="bg-white py-16">
      <div className="container mx-auto px-4 flex flex-col lg:flex-row items-center">
        <div className="lg:w-1/2 lg:pr-12 mb-8 xl:mr-16 lg:mb-0">
          <p className="text-lg text-primary mb-6">
            You can answer distribution network management challenges with our innovative software platform that enables utilities to drive efficiency, navigate complexity, and achieve sustainable growth in today's dynamic energy landscape.
          </p>
          <BookDemoButton buttonText='Get a free demo'/>
        </div>
        <div className="lg:w-1/2 flex justify-center">
          <img 
            src={screensImage} 
            alt="Platform preview" 
            className="w-[70%] h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default Introduction;