import React from 'react';

interface TrapezoidProps {
  title: string;
  description: string;
}

const Trapezoid: React.FC<TrapezoidProps> = ({ title, description }) => (
  <div className="relative w-full bg-bg-secondary overflow-hidden">
    <div className="absolute top-[-2px] left-[-2px] w-[calc(15%+4px)] h-[43px] sm:h-[63px] md:h-[84px] bg-white" style={{ clipPath: 'polygon(0 0, 0 100%, 100% 0)' }} />
    <div className="absolute bottom-[-2px] right-[-2px] w-[calc(15%+4px)] h-[43px] sm:h-[63px] md:h-[84px] bg-white" style={{ clipPath: 'polygon(100% 100%, 0 100%, 100% 0)' }} />
    <div className="relative z-10 p-6 sm:p-8 md:p-10 my-4 sm:my-6 md:my-8">
      <h2 className="text-lg sm:text-xl md:text-2xl font-bold text-primary mb-2 sm:mb-3 md:mb-4 text-center">{title}</h2>
      <p className="text-primary text-sm sm:text-base">{description}</p>
    </div>
  </div>
);

export default Trapezoid;