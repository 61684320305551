import React, { useRef, useEffect, ReactNode } from 'react';
import { motion } from 'framer-motion';

interface AnimatedSvgWrapperProps {
  children: ReactNode;
}

const AnimatedSvgWrapper: React.FC<AnimatedSvgWrapperProps> = ({ children }) => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (svgRef.current) {
      const paths = svgRef.current.querySelectorAll('path');
      paths.forEach((path, index) => {
        const length = path.getTotalLength();
        path.style.strokeDasharray = `${length + 1}`;
        path.style.strokeDashoffset = `${length + 1}`;
        path.style.strokeLinecap = 'round';
        path.style.strokeLinejoin = 'round'; 

        path.animate(
          [
            { strokeDashoffset: length + 1 },
            { strokeDashoffset: 0 }
          ],
          {
            duration: 2000,
            delay: index * 100,
            fill: 'forwards',
            easing: 'ease-in-out'
          }
        );

        const fillColor = path.getAttribute('fill');
        if (fillColor && fillColor !== 'none') {
          path.style.fillOpacity = '0';
          path.animate(
            [
              { fillOpacity: 0 },
              { fillOpacity: 1 }
            ],
            {
              duration: 1000,
              delay: index * 100 + 1000,
              fill: 'forwards',
              easing: 'ease-in-out'
            }
          );
        }
      });
    }
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {React.cloneElement(children as React.ReactElement, { ref: svgRef })}
    </motion.div>
  );
};

export default AnimatedSvgWrapper;