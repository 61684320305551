import React, { useEffect, useRef, useState } from 'react';
import { motion, useMotionValue, useSpring } from 'framer-motion';
import { ReactComponent as BeeSVG } from '../assets/images/svg/logo.svg';

interface AnimatedCursorArrowProps {
  children: React.ReactNode;
}

const AnimatedCursorArrow: React.FC<AnimatedCursorArrowProps> = ({ children }) => {
  const cursorRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isCursorActive, setIsCursorActive] = useState(false);

  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  const beeX = useSpring(0, { stiffness: 100, damping: 30 });
  const beeY = useSpring(0, { stiffness: 100, damping: 30 });
  const beeRotation = useSpring(0, { stiffness: 100, damping: 30 });

  const prevMouseX = useRef(0);
  const prevMouseY = useRef(0);
  const prevAngle = useRef(0);

  const beeOffset = 40;
  const rotationOffset = 90;
  const movementThreshold = 2;

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isCursorActive) return;

      const currentMouseX = e.clientX;
      const currentMouseY = e.clientY;

      mouseX.set(currentMouseX);
      mouseY.set(currentMouseY);

      const deltaX = currentMouseX - prevMouseX.current;
      const deltaY = currentMouseY - prevMouseY.current;

      const distanceMoved = Math.hypot(deltaX, deltaY);

      if (distanceMoved > movementThreshold) {
        let angleRadians = Math.atan2(deltaY, deltaX);
        let angleDegrees = angleRadians * (180 / Math.PI);

        let shortestAngle = angleDegrees - prevAngle.current;
        shortestAngle = ((shortestAngle + 540) % 360) - 180;
        const newAngle = prevAngle.current + shortestAngle;

        beeRotation.set(newAngle + rotationOffset);

        prevAngle.current = newAngle;

        prevMouseX.current = currentMouseX;
        prevMouseY.current = currentMouseY;
      }

      const beeXValue = beeX.get();
      const beeYValue = beeY.get();

      const angleToCursor = Math.atan2(currentMouseY - beeYValue, currentMouseX - beeXValue);

      const offsetX = Math.cos(angleToCursor) * beeOffset;
      const offsetY = Math.sin(angleToCursor) * beeOffset;

      beeX.set(currentMouseX - offsetX);
      beeY.set(currentMouseY - offsetY);
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isCursorActive, mouseX, mouseY, beeX, beeY, beeRotation]);

  const handleMouseEnter = () => {
    setIsCursorActive(true);
    document.body.style.cursor = 'none';
  };

  const handleMouseLeave = () => {
    setIsCursorActive(false);
    document.body.style.cursor = 'auto';
  };

  return (
    <div
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}

      {isCursorActive && (
        <>
          <motion.div
            ref={cursorRef}
            style={{
              position: 'fixed',
              top: mouseY,
              left: mouseX,
              width: '8px',
              height: '8px',
              backgroundColor: '#333333',
              borderRadius: '50%',
              pointerEvents: 'none',
              translateX: '-50%',
              translateY: '-50%',
            }}
          />
          <motion.div
            style={{
              position: 'fixed',
              top: beeY,
              left: beeX,
              pointerEvents: 'none',
              translateX: '-50%',
              translateY: '-50%',
              rotate: beeRotation,
            }}
          >
            <BeeSVG />
          </motion.div>
        </>
      )}
    </div>
  );
};

export default AnimatedCursorArrow;
