import React from 'react';
import Trapezoid from './Trapezoid';

const WizzgridModules: React.FC = () => {
  const modules = [
    {
      title: 'Operations',
      description: 'Optimize network operations with our comprehensive solutions for effective maintenance, outage management, crisis handling, and advanced grid management utilizing digital twin technology.'
    },
    {
      title: 'Field Support',
      description: 'Experience our innovative mobile solution transforming field operations with advanced visualization, seamless data access, and real-time support.'
    },
    {
      title: 'Analytics',
      description: 'Maximize grid potential through advanced analysis and strategic planning capabilities, ensuring the resilience of your energy infrastructure while expediting new connection requests.'
    },
    {
      title: 'Flexibility',
      description: 'Use flexibility in your distribution network by optimizing renewables, heat pump and EV charger demand, grid power flow, digital twin accuracy, and bottleneck prediction. Increase hosting capacity, maximize network usage fee.'
    }
  ];

  return (
    <div id="wizzgrid-modules" className="container mx-auto pt-16 py-8">
      <div className="flex flex-col items-center mb-8">
        <h1 className="md:text-5xl lg:text-5xl text-3xl font-bold text-primary">Wizzgrid modules</h1>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 max-w-7xl mx-auto">
        {modules.map((module) => (
          <Trapezoid key={module.title} title={module.title} description={module.description} />
        ))}
      </div>
    </div>
  );
};

export default WizzgridModules;