import React from 'react';
import { ReactComponent as GlobalIcon } from '../assets/images/svg/icons/global.svg';
import { ReactComponent as EmailIcon } from '../assets/images/svg/icons/email.svg';
import { ReactComponent as LinkedinIcon } from '../assets/images/svg/icons/linkedin.svg';

const Contact: React.FC = () => {
  return (
    <div id="contact" className="container mx-auto px-4 pt-16 pb-14">
      <div className="flex flex-col items-center mb-8">
        <h1 className="text-4xl md:text-5xl font-bold text-primary">Contact</h1>
      </div>
      <div className="flex flex-wrap justify-center gap-4">
        <ContactCard
          Icon={GlobalIcon}
          title="Webpage"
          link="https://icontest.hu"
          text="icontest.hu"
        />
        <ContactCard
          Icon={EmailIcon}
          title="Email"
          link="mailto:info@wizzgrid.com"
          text="info@wizzgrid.com"
        />
        <ContactCard
          Icon={LinkedinIcon}
          title="LinkedIn"
          link="https://linkedin.com/company/icontest/"
          text="iContest"
        />
      </div>
    </div>
  );
};

interface ContactCardProps {
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  title: string;
  link: string;
  text: string;
}

const ContactCard: React.FC<ContactCardProps> = ({ Icon, title, link, text }) => {
  return (
    <a
      href={link}
      className="bg-secondary p-4 text-center w-64 transition-all duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="mb-2">
        <Icon className="w-12 h-12 mx-auto" />
      </div>
      <h3 className="text-xl font-bold mb-1">{title}</h3>
      <p className="text-sm">{text}</p>
    </a>
  );
};

export default Contact;