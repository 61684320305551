
const Footer: React.FC = () => {
    return (
        <footer className="bg-bg-secondary text-primary py-6">
            <div className="container mx-auto px-4">
                <p className="text-center">
                    &copy; <b>iContest</b>. All rights reserved.
                </p>
            </div>
        </footer>
    );
    };

export default Footer;