import ReactGA from "react-ga4";

const TRACKING_ID = "G-V9NSX112MT";

export const initGA = () => {
  if (typeof window !== 'undefined' && localStorage.getItem('cookieConsent') === 'accepted') {
    ReactGA.initialize(TRACKING_ID, {
      gtagOptions: {
        debug_mode: false
      }
    });
  }
};

export const logPageView = () => {
  if (typeof window !== 'undefined' && localStorage.getItem('cookieConsent') === 'accepted') {
    ReactGA.send("pageview");
  }
};

export const logEvent = (category: string, action: string, label?: string, value?: number) => {
  if (typeof window !== 'undefined' && localStorage.getItem('cookieConsent') === 'accepted') {
    ReactGA.event({ category, action, label, value });
  }
};