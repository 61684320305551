import React, { useState, useEffect } from 'react';
import { initGA, logPageView } from '../utils/analytics';

const CookieConsent: React.FC = () => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowConsent(true);
      document.body.style.overflow = 'hidden'; // Disable scrolling
    }
  }, []);

  const handleConsent = (accepted: boolean) => {
    localStorage.setItem('cookieConsent', accepted ? 'accepted' : 'declined');
    setShowConsent(false);
    document.body.style.overflow = ''; // Re-enable scrolling
    if (accepted) {
      initGA();
      logPageView();
    }
  };

  if (!showConsent) return null;

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-[9998]" />
      <div className="fixed inset-x-0 bottom-0 bg-bg-secondary border-t-4 border-primary z-[9999] px-4 py-6">
        <div className="container mx-auto max-w-4xl">
          <div className="flex flex-col items-center text-center">
            <p className="text-primary mb-6 text-base font-chakra">
              We use cookies to enhance your browsing experience and analyze site traffic. 
              By clicking "Accept", you consent to our use of cookies. For more information, 
              please read our <a href="/privacy-policy.pdf" target="_blank" rel="noopener noreferrer" className="underline">Privacy Policy</a>.
            </p>
            <div className="flex justify-center space-x-4 w-full">
              <button
                onClick={() => handleConsent(true)}
                className="bg-primary text-secondary hover:bg-secondary hover:text-primary transition-colors font-medium px-6 py-2"
              >
                Accept
              </button>
              <button
                onClick={() => handleConsent(false)}
                className="bg-bg-primary text-primary border border-primary hover:bg-secondary hover:text-primary transition-colors font-medium px-6 py-2"
              >
                Decline
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookieConsent;