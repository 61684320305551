import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { Checkbox } from "@/components/ui/checkbox"
import { Button } from './ui/button';

interface BookDemoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

type FormField = 'firstName' | 'lastName' | 'email' | 'companyName' | 'message' | 'checkbox';

const BookDemoModal: React.FC<BookDemoModalProps> = ({ isOpen, onClose }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [message, setMessage] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<string | null>(null);
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    message: '',
    checkbox: '',
  });
  const [touched, setTouched] = useState({
    firstName: false,
    lastName: false,
    email: false,
    companyName: false,
    message: false,
    checkbox: false,
  });

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validateField = (field: FormField, value: string | boolean) => {
    switch (field) {
      case 'firstName':
      case 'lastName':
      case 'companyName':
      case 'message':
        return value === '' ? 'Please complete this required field.' : '';
      case 'email':
        return value === '' ? 'Please complete this required field.' : 
               !validateEmail(value as string) ? 'Please enter a valid email address.' : '';
      case 'checkbox':
        return value ? '' : 'Please agree to the terms.';
      default:
        return '';
    }
  };

  const validateForm = () => {
    const newErrors = {
      firstName: validateField('firstName', firstName),
      lastName: validateField('lastName', lastName),
      email: validateField('email', email),
      companyName: validateField('companyName', companyName),
      message: validateField('message', message),
      checkbox: validateField('checkbox', isChecked),
    };

    setErrors(newErrors);
    setTouched({
      firstName: true,
      lastName: true,
      email: true,
      companyName: true,
      message: true,
      checkbox: true,
    });
    return Object.values(newErrors).every(error => error === '');
  };

  const handleBlur = (field: FormField) => {
    setTouched(prev => ({ ...prev, [field]: true }));
    setErrors(prev => ({ ...prev, [field]: validateField(field, field === 'checkbox' ? isChecked : eval(field)) }));
  };

  const handleCheckboxChange = (checked: boolean) => {
    setIsChecked(checked);
    setTouched(prev => ({ ...prev, checkbox: true }));
    setErrors(prev => ({ ...prev, checkbox: validateField('checkbox', checked) }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);

    const scriptUrl = 'https://script.google.com/macros/s/AKfycbymi9uHBGpUGNV_Ie1LS-wVfGxY2X9Bqw24Ga_3jiNyUIVOKJO9geA0NIKg5igzZ2ZJ/exec';

    try {
      const response = await axios.get(scriptUrl, {
        params: {
          value1: `${firstName} ${lastName}`,
          value2: email,
          value3: companyName,
          value4: message
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data && response.data.status === "success") {
        setStatus(response.data.message);
      } else {
        throw new Error('Unsuccessful response from server');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus(error instanceof Error ? error.message : 'An unknown error occurred during submission.');
    } finally {
      setIsLoading(false);
      resetForm();
      onClose();
    }
  };

  const resetForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setCompanyName('');
    setMessage('');
    setIsChecked(false);
    setErrors({
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      message: '',
      checkbox: '',
    });
    setTouched({
      firstName: false,
      lastName: false,
      email: false,
      companyName: false,
      message: false,
      checkbox: false,
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => { resetForm(); onClose(); }}>
      <DialogContent className="sm:max-w-[425px] bg-bg-primary">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold text-primary">Book a Free Demo</DialogTitle>
          <DialogDescription className="text-primary/70">
            Fill out the form below to schedule your free demo.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-primary">
              Email<span className="text-red-500">*</span>
            </label>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={() => handleBlur('email')}
              className={`mt-1 block w-full transition-colors duration-200
                ${touched.email && errors.email ? 'border-red-500' : 'border-gray-300'}
                hover:border-primary/50 focus:border-primary/50 focus:ring focus:ring-blue-200`}
            />
            {touched.email && errors.email && <p className="mt-1 text-sm text-red-500">{errors.email}</p>}
          </div>
          <div>
            <label htmlFor="companyName" className="block text-sm font-medium text-primary">
              Company name<span className="text-red-500">*</span>
            </label>
            <Input
              id="companyName"
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              onBlur={() => handleBlur('companyName')}
              className={`mt-1 block w-full transition-colors duration-200
                ${touched.companyName && errors.companyName ? 'border-red-500' : 'border-gray-300'}
                hover:border-primary/50 focus:border-primary/50 focus:ring focus:ring-blue-200`}
            />
            {touched.companyName && errors.companyName && <p className="mt-1 text-sm text-red-500">{errors.companyName}</p>}
          </div>
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium text-primary">
              First name<span className="text-red-500">*</span>
            </label>
            <Input
              id="firstName"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              onBlur={() => handleBlur('firstName')}
              className={`mt-1 block w-full transition-colors duration-200
                ${touched.firstName && errors.firstName ? 'border-red-500' : 'border-gray-300'}
                hover:border-primary/50 focus:border-primary/50 focus:ring focus:ring-blue-200`}
            />
            {touched.firstName && errors.firstName && <p className="mt-1 text-sm text-red-500">{errors.firstName}</p>}
          </div>
          <div>
            <label htmlFor="lastName" className="block text-sm font-medium text-primary">
              Last name<span className="text-red-500">*</span>
            </label>
            <Input
              id="lastName"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              onBlur={() => handleBlur('lastName')}
              className={`mt-1 block w-full transition-colors duration-200
                ${touched.lastName && errors.lastName ? 'border-red-500' : 'border-gray-300'}
                hover:border-primary/50 focus:border-primary/50 focus:ring focus:ring-blue-200`}
            />
            {touched.lastName && errors.lastName && <p className="mt-1 text-sm text-red-500">{errors.lastName}</p>}
          </div>
          <div>
            <label htmlFor="message" className="block text-sm font-medium text-primary">
              Message<span className="text-red-500">*</span>
            </label>
            <Textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onBlur={() => handleBlur('message')}
              className={`mt-1 block w-full min-h-[100px] transition-colors duration-200
                ${touched.message && errors.message ? 'border-red-500' : 'border-gray-300'}
                hover:border-primary/50 focus:border-primary/50 focus:ring focus:ring-blue-200`}
            />
            {touched.message && errors.message && <p className="mt-1 text-sm text-red-500">{errors.message}</p>}
          </div>
          <div className="flex items-start space-x-2">
            <Checkbox 
              id="terms" 
              checked={isChecked}
              onCheckedChange={handleCheckboxChange}
              className="mt-1 border-gray-300 text-secondary focus:ring-secondary"
            />
            <label htmlFor="terms" className="text-sm text-primary/70">
              By submitting this form, you agree to the transfer, storing, and processing of your data as outlined in our <a href="/privacy-policy.pdf" target="_blank" rel="noopener noreferrer" className="underline">privacy policy</a>. Your information will be used solely to process this demo request.<span className="text-red-500">*</span>
            </label>
          </div>
          {touched.checkbox && errors.checkbox && <p className="mt-1 text-sm text-red-500">{errors.checkbox}</p>}
          
          <Button 
            type="submit" 
            disabled={isLoading}
            className="w-full bg-primary text-white hover:bg-[#C7FF00] hover:text-primary transition-colors font-medium disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isLoading ? 'Sending...' : 'Book a demo'}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default BookDemoModal;