import { ReactComponent as Icon1 } from '../assets/images/svg/icons/about_1.svg';
import { ReactComponent as Icon2 } from '../assets/images/svg/icons/about_2.svg';
import { ReactComponent as Icon3 } from '../assets/images/svg/icons/about_3.svg';
import { ReactComponent as Icon4 } from '../assets/images/svg/icons/about_4.svg';
import { ReactComponent as Icon5 } from '../assets/images/svg/icons/about_5.svg';
import { ReactComponent as Icon6 } from '../assets/images/svg/icons/about_6.svg';

const FeaturesSection = () => {
  const features = [
    {
      icon: Icon1,
      title: "Monitoring",
      description: "Maximize visibility, control and analysis with advanced network monitoring, based on digital twin technology. Streamline network operations with our 360⁰ view, integrated, centralized cockpit, that provides exceptional user experience and effective problem resolution, optimizing workforce management and prioritizing outage resolution."
    },
    {
      icon: Icon2,
      title: "Network optimization",
      description: "Optimize network planning by identifying bottlenecks and reinforcing your network at the most important points based on data driven decisions."
    },
    {
      icon: Icon3,
      title: "AR",
      description: "Empower field teams with field work support tools providing real-time data, augmented reality for troubleshooting, and intuitive interfaces to reduce training and administrative burdens."
    },
    {
      icon: Icon4,
      title: "CAPEX / OPEX optimization",
      description: "Optimize your network with flexibility for enhanced grid performance and strategic decision-making (CAPEX/OPEX optimization)."
    },
    {
      icon: Icon5,
      title: "Compliance",
      description: "Ensure regulatory compliance with streamlined reporting. We assist you in complying with the requirements outlined in §14a EnWG."
    },
    {
      icon: Icon6,
      title: "Demand side response",
      description: "Leverage predictive analytics to balance DSR (demand side response) and manage network reliability amidst evolving energy demands."
    }
  ];

  return (
    <div id="features" className="bg-bg-secondary py-16">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center mb-8">
            <h1 className="text-5xl font-bold text-primary">Features</h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="p-6">
                <feature.icon className="w-12 h-12 mb-4 [&_path]:stroke-primary" />
                <h3 className="text-xl font-semibold mb-2 text-primary">{feature.title}</h3>
                <p className="text-primary">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;