import React from 'react';
import { Swiper as SwiperCore, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import Hexoid, { HexoidProps } from './Hexoid';

const hexoidData: HexoidProps[] = [
  { title: "Customer", text: "Informed, fast resolution, network quality, new connection", color: "blue" },
  { title: "Control", text: "Real-time data, state estimation, decision making, intervene", color: "white" },
  { title: "Effectiveness", text: "Integrated view, fieldwork, call center, night shift, planned works", color: "gray" },
  { title: "Future", text: "Digital twin, SMART, sensors, active assets, automation", color: "green" },
  { title: "Visibility", text: "Topology, status, congestion, outage, power quality", color: "blue" }
];

const Benefits: React.FC = () => {
  return (
    <div id="benefits" className="container mx-auto px-4">
        <div className="flex flex-col items-center mt-14 mb-8">
            <h1 className="text-5xl font-bold text-primary">Benefits</h1>
            <h3 className="text-xl text-primary mt-4">Proven, measurable financial and operational results.</h3>
        </div>
      <div className="md:hidden">
        <SwiperCore
          modules={[Pagination]}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          {hexoidData.map((hexoid) => (
            <SwiperSlide key={hexoid.title}>
              <div className="flex justify-center py-8">
                <Hexoid {...hexoid} />
              </div>
            </SwiperSlide>
          ))}
        </SwiperCore>
      </div>

      <div className="hidden md:block">
        <div className="flex flex-col items-center">
          <div className="flex justify-center -mx-3">
            {hexoidData.slice(0, 3).map((hexoid) => (
              <div key={hexoid.title} className="mx-3">
                <Hexoid {...hexoid} />
              </div>
            ))}
          </div>
          <div className="flex justify-center -mx-3 -mt-14">
            {hexoidData.slice(3).map((hexoid) => (
              <div key={hexoid.title} className="mx-3">
                <Hexoid {...hexoid} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;