import React, { useEffect } from 'react';
import i18next from 'i18next';
import './App.css';
import de from './localization/de.json';
import en from './localization/en.json';
import hu from './localization/hu.json';
import Header from './components/Header';
import Hero from './components/Hero';
import WizzgridModules from './components/WizzGridModules';
import References from './components/References';
import FeaturesSection from './components/Features';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Introduction from './components/Introduction';
import Benefits from './components/Benefits';
import CookieConsent from './components/CookieConsent';
import { logPageView } from './utils/analytics';

i18next.init({
  lng: navigator.language.substring(0, 2),
  resources: { hu: { translation: hu }, en: { translation: en }, de: { translation: de } }
});

function App() {
  useEffect(() => {
    logPageView();
  }, []);

  return (
    <div>
      <Header/>
      <Hero/>
      <Introduction/>
      <FeaturesSection/>
      <Benefits/>
      <WizzgridModules/>
      <References/>
      <AboutUs/>
      <Contact/>
      <Footer/>
      <CookieConsent/>
    </div>
  );
}

export default App;