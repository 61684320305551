import React from 'react';

const AboutUs: React.FC = () => {
  return (
    <div id="about-us" className="bg-bg-secondary pt-16 p-12 flex justify-center">
        <div className="max-w-[800px]">
            <h2 className="text-4xl md:text-5xl font-bold text-primary mb-6 text-center">About us</h2>
            <div className="space-y-6">
                <p className="text-primary">
                Since 2014, we have been providing energy IT solutions and consulting services. Our goal is to provide our clients with market-leading solutions to help grow their businesses and maximize their potential. We have a deep understanding of the energy sector, our primary focus is to understand our customer's specifics, their processes and daily operation so that we can tailor our solutions accordingly providing efficiency, user friendliness, smooth and hassle-free operation.
                </p>
                <p className="text-primary">
                    As a prominent player in the Energy Software Solution sector, we are recognized for our:
                </p>
                <ul className="list-disc list-inside text-primary space-y-2">
                <li>Reliability: Long-term partnerships with leading energy companies.</li>
                <li>Company Culture: Rooted in trust, partnership, excellence, and innovation.</li>
                <li>Academic Collaboration: Close collaboration with universities and research institutes.</li>
                </ul>
            </div>
        </div>
    </div>
  );
};

export default AboutUs;