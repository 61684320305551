import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { initGA, logEvent } from './utils/analytics';

initGA();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const sendToAnalytics = (metric: any) => {
  const { id, name, value } = metric;
  logEvent('Web Vitals', name, id, Math.round(name === 'CLS' ? value * 1000 : value));
};

reportWebVitals(sendToAnalytics);
