import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';

import ref1 from '../assets/images/png/references/ref1.png';
import ref2 from '../assets/images/png/references/ref2.png';
import ref3 from '../assets/images/png/references/ref3.png';
import ref4 from '../assets/images/png/references/ref4.png';
import ref5 from '../assets/images/png/references/ref5.png';
import ref6 from '../assets/images/png/references/ref6.png';
import ref7 from '../assets/images/png/references/ref7.png';
import ref8 from '../assets/images/png/references/ref8.png';

const references = [
  { id: 'ref1', name: 'E.ON', image: ref1 },
  { id: 'ref2', name: 'Audax', image: ref2 },
  { id: 'ref3', name: 'MVM', image: ref3 },
  { id: 'ref4', name: 'OPUS TITASZ', image: ref4 },
  { id: 'ref5', name: 'MAVIR', image: ref5 },
  { id: 'ref6', name: 'CET', image: ref6 },
  { id: 'ref7', name: 'OMV', image: ref7 },
  { id: 'ref8', name: 'Lufthansa', image: ref8 },
];

const ReferenceImage: React.FC<{ reference: typeof references[0] }> = ({ reference }) => (
    <div className="flex items-center justify-center h-20 px-4">
      <img
        src={reference.image}
        alt={reference.name}
        className="max-h-16 max-w-full object-contain"
      />
    </div>
  );

const ReferenceSlider: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div id="references" className="bg-white">
      <div className="container mx-auto px-4 pb-8">
      <div className="flex flex-col items-center mt-14 mb-8">
            <h1 className="text-4xl md:text-5xl font-bold text-primary">References</h1>
            <h3 className="text-xl text-primary mt-4 text-center">
                <span>DSOs and a TSO are our valued customers.</span>
                <br/>
                <span>Our clients provide energy services to 4.139 million households with the help of our software.</span>
            </h3>
        </div>
        {isMobile ? (
          <Swiper
            modules={[Autoplay]}
            spaceBetween={20}
            slidesPerView="auto"
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            loop={true}
            centeredSlides={false}
            className="flex items-center"
          >
            {references.map((reference) => (
              <SwiperSlide key={reference.id} className="!w-auto">
                <ReferenceImage reference={reference} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className="grid grid-cols-4 lg:grid-cols-8 gap-4">
            {references.map((reference) => (
              <ReferenceImage key={reference.id} reference={reference} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferenceSlider;